<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-11">Tasarımcı İstatistikleri</div>
            <div class="col-1">
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="3">
              <CInput
                description="Başlangıç Tarihi"
                type="date"
                v-model="startDate"
                horizontal
              />
            </CCol>
            <CCol col="3">
              <CInput
                description="Bitiş Tarihi"
                type="date"
                v-model="endDate"
                horizontal
              />
            </CCol>
            <CCol col="3">
              <CSelect
                description="Tasarımcı"
                placeholder="Seçiniz"
                :options="activeDesigners"
                type="text"
                :value.sync="designerId"
                horizontal
              />
            </CCol>
            <CCol col="3">
              <CButton color="success" @click="getDesignerStatistics(startDate, endDate, designerId)">Uygula</CButton>&nbsp;&nbsp;
            </CCol>
          </CRow>
          <CCol col="12">
            <CDataTable
              :items="designerStatistics"
              :fields="fields"
              :loading="loading"
              :items-per-page="10"
              pagination
              clickable-rows
              hover
              v-on:refresh="getDesignerStatistics(startDate,endDate,designerId)"
            >
              <template #lastSaleTime="{item}">
                <td> {{ item.lastSaleTime && dateFormat(item.lastSaleTime) }} </td>
              </template>
            
            </CDataTable>
          </CCol>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import moment from "moment";
export default {
  name: 'Designers',
  data () {
    return {
      loading: false,
      fields: [
        {key: 'name', label: 'Tasarımcı', _style: 'font-size:12px'},
        {key: 'productName', label: 'Ürün', _style: 'font-size:12px'},
        {key: 'lastSaleTime', label: 'Satış Zamanı', _style: 'font-size:12px'},
        {key: 'soldItems', label: 'Adet', _style: 'font-size:12px'}
      ],
      designersData: {
        designers: [],
        count: 0
      },
      activeDesignersNameList: [],
      activeDesignerList: [],
      startDate:"",
      endDate:"",
      designerId:"",
    }
  },
  computed: {
    activeDesigners: function(){
      let data = []
      this.$store.getters.activeDesigners.map(r => data.push({value:r.id, label: r.name}))
      return data
    },
    designerStatistics: function(){
      return this.$store.getters.designerStatistics
    },
  },
  methods: {
    async getDesignerStatistics(startDate,endDate,designerId) {
      startDate = moment(startDate).format('YYYY-MM-DDTHH:mm:ss:SSZ')
      endDate = moment(endDate).format('YYYY-MM-DDTHH:mm:ss:SSZ')
      let params = {
        "startDate": startDate,
        "endDate": endDate,
        "designerId": designerId
      };
      await this.$store.dispatch('designerStatistic_list', params)
    },
    dateFormat(data){
      return moment(data).format('DD.MM.YYYY hh:mm:ss')
    },
    setDesigner(item) {
      this.activeDesignerList.map(d=> {
        if (item.target.value === d.name) {
            this.designerId = d.id;
        }
      })
    }
  },
  created() {
    this.$store.dispatch('activeDesigner_list');
    var today = new Date();
    this.endDate = moment(today).format('YYYY-MM-DD');
    this.startDate = moment().subtract(3,"month").format('YYYY-MM-DD');
    this.getDesignerStatistics(this.startDate,this.endDate,this.designerId);
  }
}
</script>
